import { InputBase } from '@material-ui/core';
import { MailOutline } from '@material-ui/icons';
import {
  useEmailInput,
  useSendinblueSubscription,
} from '@terra-dev/sendinblue';
import { links, sendinblueApiKey, landingMobileLayout } from 'env';
import styled from 'styled-components';
import footBg from './assets/footbg.png';

import TwitterImage from './assets/Twitter.svg';
import TelegramImage from './assets/telegram.png';
import MediumImage from './assets/medium.png';
import DiscordImage from './assets/discord.png';
import GithubImage from './assets/github.png';
import bg from './assets/bg7.png';
import mobileBg from './assets/mobile-bottom-bg.png';
import linkBg from './assets/link-bg.png';

export interface SubscribeProps {
  className?: string;
}

// should works now!!
function SubscribeForm() {
  const [email, setEmail, validEmail] = useEmailInput();

  const [subscribeEmail, { status }] =
    useSendinblueSubscription(sendinblueApiKey);

  return (
    <div>
      <p>
        Sign up for our newsletter to receive product news, updates and special
        invites.
      </p>
      {status === 'in-progress' ? (
        <div className="email">
          <MailOutline /> Please wait...
        </div>
      ) : status === 'success' ? (
        <div className="email">
          <MailOutline /> Thanks for subscribing.
        </div>
      ) : (
        <div className="email">
          <MailOutline />
          <InputBase
            type="email"
            fullWidth
            placeholder="Enter Your email address"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
          <button disabled={!validEmail} onClick={() => subscribeEmail(email)}>
            Subscribe
          </button>
        </div>
      )}
    </div>
  );
}
function SubscribeBase({ className }: SubscribeProps) {
  const showSubscribe = false;
  let subscribeForm;
  if (showSubscribe) {
    subscribeForm = <SubscribeForm />;
  }
  return (
    <section className={className}>
      {subscribeForm}
      <div className="community">
        <div className="join">
          <h2>Join The Community</h2>
          {/*<p>hello@opensky.finance</p>*/}
          {/* <p>
            Follow us on Twitter for all things related to DeFi and NFTs and
            cryptocurrencies in general.
          </p> */}
          <span className='mail'>hello@opensky.finance</span>
        </div>

        <div className="bottom">
          <div className="links">
            <a
              href={links.twitter}
              target="_blank"
              rel="noreferrer"
              className="linkitem"
            >
              <div className='logo-bg'>
                <img src={TwitterImage} alt="Twitter" />
              </div>
              <span>Twitter</span>
            </a>

            <a
              href={links.discord}
              target="_blank"
              rel="noreferrer"
              className="linkitem"
            >
              <div className='logo-bg'>
                <img src={DiscordImage} alt="Discord" />
              </div>
              <span>Discord</span>
            </a>

            <a
              href={links.blog}
              target="_blank"
              rel="noreferrer"
              className="linkitem"
            >
              <div className='logo-bg'>
                <img src={MediumImage} alt="Medium" />
              </div>
              <span>Medium</span>
            </a>

            <a
              href={links.telegram}
              target="_blank"
              rel="noreferrer"
              className="linkitem"
            >
              <div className='logo-bg'>
                <img src={TelegramImage} alt="Telegram" />
              </div>
              <span>Telegram</span>
            </a>

            <a
              href={links.github}
              target="_blank"
              rel="noreferrer"
              className="linkitem"
            >
              <div className='logo-bg'>
                <img src={GithubImage} alt="Github" />
              </div>
              <span>Github</span>
            </a>
          </div>
          <span className="copyright">
            All rights reserved © 2023 OpenSky Labs
          </span>
        </div>
      </div>
    </section>
  );
}

export const Subscribe = styled(SubscribeBase)`
  width: 100vw;
  height: 790px;
  padding: 32px;
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 1.5rem;
    color: #fff;
  }

  .email {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.textColor};

    margin-top: 2rem;

    display: flex;
    height: 6rem;

    padding: 0 0.5rem 0 2.5rem;
    align-items: center;

    border-radius: 0.5rem;

    width: 100%;
    max-width: 72rem;

    background-color: #f6f6f6;

    svg {
      color: #cccccc;
      font-size: 2rem;
      margin-right: 1.25rem;
      transform: translateY(0.1rem);
    }

    input::placeholder {
      color: #cccccc;
      opacity: 1;
    }

    button {
      cursor: pointer;

      width: 11rem;

      border: 0;
      outline: none;
      background-color: transparent;

      padding: 0.5rem 2.5rem;
      border-radius: 0;

      border-left: 1px solid #e2e2e2;
      color: ${({ theme }) => theme.textColor};

      &:disabled {
        cursor: default;
        color: #b5b5b5;
      }
    }
  }

  .community {
    width: 100%;
    height: 726px;
    display: flex;
    background-size: 100% 100%;
    background-image: url('${bg}');
    flex-direction: column;
    justify-content: center;
  }

  .join {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    text-align: center;
    color: #fff;
    margin-top: 164px;

    h2 {
      font-size: 88px;
      font-family: 'Kanit';
      text-align: center;
    }

    .mail {
      margin-top: 22px;
      font-size: 20px;
      font-weight: 300;
      color: rgba(255,255,255,0.65);
    }

    p {
      text-align: left;
      color: #fff;
      font-size: 1.5rem;
      font-weight: 500;
      margin-top: 1.5rem;
    }
  }

  .bottom {
    margin-top: 67px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .links {
    display: flex;
    width: 936px;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;

    .linkitem {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      .logo-bg {
        width: 104px;
        height: 104px;
        background-image: url('${linkBg}');
        background-repeat: no-repeat;
        background-size: 104px 104px;
        display: flex;
        justify-content: center; 
        align-items: center;
        img {
          width: 8.67rem;
          height: 8.67rem;
        }
      }

      span {
        margin-top: 2.5rem;
        
        color: #fff;
        font-size: 1.67rem;
        font-weight: 500;

        &:not(:last-child) {
          margin-right: 3rem;
        }

        &:hover {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .copyright {
    width: 900px;
    padding: 29px 0;
    border-color: #252D41;
    border-top-style: solid;
    color: #fff;
    font-size: 20px;
    margin-top: 11rem;
    text-align: center;
    color: rgba(255, 255, 255, 0.85);
  }

  @media (max-width: ${landingMobileLayout}px) {
    padding: 0;
    height: auto;

    .community {
      width: 100%;
      height: auto;
      flex-direction: column; 
      align-items: center;
      background-image: url('${mobileBg}');
    }

    .bottom {
      flex-direction: column;
      align-items: center;
    }

    .copyright {
      font-size: 12px;
      margin-top: 114px;
      text-align: center;
    }

    .join {
      width: 280px;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-top: 80px;
      margin-bottom: 0;

      h2 {
        font-size: 36px;
      }

      h3 {
        font-size: 32px;
        padding: 0 47px;
        font-weight: bold;
      }

      p {
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        margin-top: 16px;
      }
    }

    .links {
      width: 248px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 0px;

      .linkitem {
        margin: 10px;
        width: 56px;
        height: 78px;
        .logo-bg {
          width: 56px;
          height: 56px;
          background-size: 56px 56px;
          img {
            width: 40px;
            height: 40px;
          }
        }
        span {
          font-size: 12px;
          font-weight: 400;
          margin-top: 8px;
        }
      }
    }
    .copyright {
      width: 100vw;
      margin-top: 78px;
      padding: 18px 0;
    }
  }
`;
