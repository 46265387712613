// import { CircleArrowRight } from '@anchor-protocol/icons';
import { useElementIntersection } from '@terra-dev/use-element-intersection';
import { links, landingMobileLayout } from 'env';
import { useRef } from 'react';
import styled from 'styled-components';
import gifBg from './assets/gifBg.png';
import arrow from './assets/arrow-right-white.svg';
import nft from './assets/nft.webp';
import bg from './assets/bg4.webp';

const domHeight =
  window.innerWidth > landingMobileLayout
    ? (window.innerHeight > 796 ? window.innerHeight : 796) + 'px'
    : 'auto';

export interface LiquidationProps {
  className?: string;
}

function LiquidationBase({ className }: LiquidationProps) {
  const elementRef = useRef<HTMLElement>(null);

  const elementIntersection = useElementIntersection({
    elementRef,
    threshold: 0.4,
    observeOnce: true,
  });

  return (
    <section
      ref={elementRef}
      className={className}
      data-intersection={elementIntersection?.isIntersecting}
      style={
        {
          // height: window.innerWidth > landingMobileLayout && false ? '880px' : 'auto',
        }
}
    >
      <div className="content-wrap">
        <figure>
        </figure>
        <article>
          <h2> No forced liquidation based on price volatility </h2>
          <p>
          OpenSky is the first mover on Time-based liquidation which means no auto liquidation risk for our borrowers
          </p>

          <a
            className="btn-link"
            href={links.liquidation.url}
            target="_blank"
            rel="noreferrer"
          >
            {links.liquidation.text} <span></span>
          </a>
          {/* <div className='mask-bg'></div> */}
        </article>
      </div>
      <div className="bottom"></div>
    </section>
  );
}

export const Liquidation = styled(LiquidationBase)`
  // ---------------------------------------------
  // style
  // ---------------------------------------------
  width: 100vw;
  height: ${domHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // background: linear-gradient(180deg, #F5F5F6 0%, rgba(245, 245, 246, 0.87) 20.04%, rgba(245, 245, 246, 0) 88.02%);
  background-size: 100% 100%;
  background-image: url('${bg}');
  background-position: bottom center;
  background-repeat: no-repeat;
  position: relative;

  .bottom{
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0rem;
    background-size: 100% 100%;
    background-image: url('${nft}');
    background-repeat: no-repeat;
  }

  .content-wrap {
    max-width: 100rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 10rem;
    margin-bottom: 10rem;
    z-index: 2;
    .title {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      img {
        width: 4rem;
        height: 4rem;
        margin-right: 1rem;
      }
      span {
        font-weight: 600;
        font-size: 2.5rem;
      }
    }
  }

  article {
    width: 940px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20rem;
    h2 {
      font-size: 64px;
      line-height: 64px;
      font-weight: 400;
      font-family: 'Kanit';
      color: #ffffff;
      text-align: center;
    }

    p {
      width: 820px;
      margin-top: 2.5rem;
      text-align: center;
      font-size: 20px;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.65);
      word-break: break-word;
      white-space: break-spaces;
    }

    a.btn-link {
      margin-top: 4rem;
      width: 200px;
      height: 56px;
      border-radius: 100px;

      display: inline-flex;
      justify-content: center;
      align-items: center;

      padding: 0;
      color: #ffffff;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;

      svg {
        font-size: 1em;
        transform: scale(1.4) translate(4px, 2px);
      }

      span {
        margin-left: 2px;
        width: 12px;
        height: 12px;
        background-image: url('${arrow}');
        background-repeat: no-repeat;
        background-size: 1.2rem 1.2rem;
      }
    }
  }

  a.btn-link:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 100px;
    background: linear-gradient(to right, #065DFF, #FB43FF);
  }

  figure {
    > div {
      width: 100%;
      height: auto;

      padding: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  figure {
    opacity: 0;
    transform: translateX(-10%) scale(1.1);
    transition: opacity 1s ease-out, transform 0.3s ease-in-out;
  }

  &[data-intersection='true'] {
    article {
      h2,
      p {
        opacity: 1;
        transform: none;
      }
    }

    figure {
      opacity: 1;
      transform: none;
    }
  }

  @media (max-width: ${landingMobileLayout}px) {
    .bottom{
      flex-grow: 1;
      flex-shrink: 0;
      width: 100%;
      height: 368px;
      background-position: bottom center;
      background-repeat: no-repeat;
    }

    .content-wrap {
      margin-left: 0px;
      flex-direction: column;
      justify-content: center;
      padding: 0px 20px;
    }

    article {
      width: 100%;
      padding-left: 6px;
      padding-right: 6px;
      padding-top: 32px;

      h2 {
        font-size: 36px;
        line-height: 36px;
        font-weight: 600;
      }
      p {
        width: 100%;
        font-size: 14px;
      }
      a.btn-link {
        margin-top: 24px;
        font-size: 16px;
        width: 140px;
        height: 40px;
      }
    }

    figure {
      > div {
        width: 300px;
        height: 300px;
        border-radius: 30px;
        background-image: url('${gifBg}');
        background-position: center center;
        background-size: 528px 528px; 
        background-repeat: no-repeat;
  
        padding: 0;
        position: relative;
  
        img {
          width: 252px;
          position: absolute;
          top: 18px;
          left: 24px;
        }
      }
    }
  }
`;
