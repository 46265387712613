export const positions = [
  [-1, -1, 0],
  [1, -1, 0],
  [1, 1, 0],
  [-1, 1, 0],
]

export const uv = [
  [0, 0], [1, 0], [1, 1], [0, 1],
]

export const elements = [
  0, 1, 2, 0, 2, 3,
]
