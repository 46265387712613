// import { onProduction } from 'base/env';

export const screen = {
  mobile: { max: 510 },
  // mobile : @media (max-width: ${screen.mobile.max}px)
  tablet: { min: 511, max: 830 },
  // tablet : @media (min-width: ${screen.tablet.min}px) and (max-width: ${screen.tablet.max}px)
  pc: { min: 831, max: 1439 },
  // pc : @media (min-width: ${screen.pc.min}px)
  monitor: { min: 1440 },
  // monitor : @media (min-width: ${screen.pc.min}px) and (max-width: ${screen.pc.max}px)
  // huge monitor : @media (min-width: ${screen.monitor.min}px)
} as const;

export const landingMobileLayout = 680;
// export const landingMobileLayout = 1200;

export const headerHeight = 64;

//export const FINDER = 'https://finder.terra.money';

//1st page top right (DEVELOPERS) -> https://docs.anchorprotocol.com/developers-terra/anchor.js
//2nd page Anchor Offers Better Yield -> https://docs.anchorprotocol.com/protocol/overview
//3rd page Anchor offers Easier Integrations -> https://docs.anchorprotocol.com/developers-terra/anchor.js
//4th page Anchor offers frictionless access -> https://app.anchorprotocol.com

export const meta = {
  headerTitle: 'OpenSky',
};

export const branchName = process.env.REACT_APP_VERCEL_GIT_COMMIT_REF || '';
export const gitHash = process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA || '';
const domainPrefix = branchName === 'main' ? '' : `${branchName}.`;

console.log('====> branchName, gitHash, buildTime :', branchName, gitHash);

export const links = {
  // dashboard: {
    // show: branchName === 'dev',
    // url: `https://${domainPrefix}dashboard.opensky.market`,
    // text: 'Dashboard',
  // },
  app: {
    // show: branchName !== 'main',
    show: true,
    url: `https://app.opensky.finance/`,
    comingSoonUrl: 'https://coming-soon.opensky.market',
    text: 'Launch APP',
    // text: 'TestNet APP',
  },
  developers: {
    url: 'https://docs.opensky.loan',
    // url: onProduction
    //   ? 'https://docs.anchorprotocol.com/developers-terra/'
    //   : 'https://app.gitbook.com/@anchor-protocol/s/anchor-2/',
    text: 'DOCS',
  },
  whitepaper: {
    text: 'Whitepaper',
    url: '/docs/OpenSky.Whitepaper.V1.3.pdf',
  },
  faq: {
    text: 'FAQ',
    url: 'https://faq.opensky.finance',
  },
  docs: {
    text: 'DOCS',
    url: 'https://docs.opensky.finance',
  },
  betterYield: {
    // url: onProduction
    // ? 'https://docs.anchorprotocol.com/protocol/overview'
    // : 'https://app.gitbook.com/@anchor-protocol/s/anchor-2/protocol/overview',
    url: 'https://docs.opensky.finance/lending-protocol/how-opensky-works#opensky-instant-loan',
    text: 'Learn more',
  },
  easierIntegration: {
    // url: onProduction
    // ? 'https://docs.anchorprotocol.com/developers-terra/anchor.js'
    // : 'https://app.gitbook.com/@anchor-protocol/s/anchor-2/developers-terra/anchor.js',
    url: 'https://docs.opensky.finance/lending-protocol/how-opensky-works#opensky-bespoke-loan',
  },
  frictionlessAccess: {
    // url: onProduction
    // ? 'https://app.anchorprotocol.com'
    // : 'https://app-staging.anchorprotocol.com/earn',
    url: 'https://faq.opensky.finance/',
  },
  peerToPeer: {
    url: 'https://docs.opensky.finance/lending-protocol/how-opensky-works#opensky-bespoke-loan',
    text: 'Learn more'
  },
  peerToPool: {
    url: 'https://docs.opensky.finance/lending-protocol/how-opensky-works#opensky-instant-loan',
    text: 'Learn more'
  },
  liquidation: {
    url: 'https://docs.opensky.finance/lending-protocol/liquidation-mechanism',
    text: 'Learn more'
  },
  contact: 'mailto:hello@opensky.market',
  github: 'https://github.com/OpenSky-Finance',
  twitter: 'https://twitter.com/OpenSkyFinance',
  discord: 'https://discord.gg/WDPSHnQbN8',
  telegram: 'https://t.me/OpenSkyLabs',
  blog: 'https://openskyfinance.medium.com',
  // forum: 'https://forum.anchorprotocol.com/',
  // terra: 'https://terra.money/',
};

export const sendinblueApiKey =
  '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';
