// import { CircleArrowRight } from '@anchor-protocol/icons';
import { useElementIntersection } from '@terra-dev/use-element-intersection';
import { landingMobileLayout } from 'env';
import { useRef } from 'react';
import styled from 'styled-components';
import topBg from './assets/topbg.png';

import peckshield from './assets/peckshield.png';
import certik from './assets/certik.png';
import bg from './assets/bg6.webp';
import auditorBg from './assets/auditor-bg.png';

export interface AuditorsProps {
  className?: string;
}

function AuditorsBase({ className }: AuditorsProps) {
  const elementRef = useRef<HTMLElement>(null);

  const elementIntersection = useElementIntersection({
    elementRef,
    threshold: 0.4,
    observeOnce: true,
  });

  return (
    <section
      ref={elementRef}
      className={className}
      data-intersection={elementIntersection?.isIntersecting}
      style={
        {
          // height: window.innerWidth > landingMobileLayout ? '880px' : 'auto',
        }
      }
    >
      <div className="content-wrap">
        <article>
          <h2> Auditors </h2>
        </article>

        <figure>
          <div className="links">
            <a className="linkitem" href="https://www.certik.com/projects/opensky-finance" target='_blank'>
              <img src={certik} alt="Certik" />
            </a>
            <a className="linkitem" href="https://github.com/peckshield/publications/tree/master/audit_reports/PeckShield-Audit-Report-OpenSky-v1.0.pdf" target='_blank'>
              <img src={peckshield} alt="PeckShield" />
            </a>
          </div>
        </figure>
      </div>
      {/* <div className="abs"></div> */}
    </section>
  );
}

export const Auditors = styled(AuditorsBase)`
  // ---------------------------------------------
  // style
  // ---------------------------------------------
  position: relative;
  display: flex;
  justify-content: center;

  // background: linear-gradient(180deg, #071026 0%, #273865 100%);
  width: 100vw;
  // background-size: 100% 100%;
  // background-image: url('${bg}');

  .abs {
    position: absolute;
    width: 100rem;
    height: 28rem;
    bottom: 0;
    background-image: url('${topBg}');
    background-position: bottom center;
    background-size: 63rem 28rem; 
    background-repeat: no-repeat;
  }

  .content-wrap {
    width: 100rem;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  article {
    width: 100%;
    text-align: center;
    h2 {
      font-family: 'Kanit';
      font-size: 88px;
      color: #fff;
      margin-bottom: 7rem;
      text-align: center;
    }
  }

  .bottom-title {
    margin-top: 7rem;
  }

  figure {
    display: flex;
    flex-direction: row;
    width: 100%;
    h3 {
      color: #fff;
    }
    
    .links {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      .linkitem {
        width: 48.3rem;
        height: 27rem;
        padding: 3rem;
        align-items: center;
        // background-color: #252D41;
        background-image: url('${auditorBg}');
        background-repeat: no-repeat;
        background-size: 580px 270px;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 28rem;
          height: 7rem;
          border-radius: 0.7rem;
        }
      }
    }
  }

  @media (max-width: ${landingMobileLayout}px) {
    margin: 0px;
    .content-wrap {
      max-width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 64px;
    }

    article {
      text-align: center;
      h2 {
        font-size: 36px;
        color: #fff;
        text-align: center;
        font-weight: 600;
        margin-bottom: 0;
      }
    }

    figure {
      width: 100vw;
      .links {
        width: 100vw;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .linkitem {
          margin-top: 2rem;
          margin-left: 2rem;
          margin-right: 2rem;
          height: 20rem;
        }
      }
    }
    .abs {
      display: none;
    }
  }
`;
