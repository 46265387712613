import { isTouchDevice } from '@terra-dev/is-touch-device';
import { useElementIntersection } from '@terra-dev/use-element-intersection';
import { GUI } from 'dat.gui';
import { headerHeight, landingMobileLayout } from 'env';
// import { headerHeight, sendinblueApiKey, landingMobileLayout } from 'env';

import { useEffect, useRef, useState } from 'react';
import Regl from 'regl';
import Stats from 'stats.js';
import styled from 'styled-components';
import { animate } from '../graphics/cube-3d';
import { play, stop } from '../graphics/cube-3d/renderer';
// import { MailOutline } from '@material-ui/icons';
// import { InputBase } from '@material-ui/core';
import { links } from 'env';
// import line from './assets/line.png';
import bg from './assets/top-bg.webp';
import mobileBg from './assets/mobile-top-bg.png';
import earnETH from './assets/earn-ETH.png';
import earnBTC from './assets/earn-BTC.png';
import aaveLogo from './assets/aave-logo.png';

// import {
//   useEmailInput,
//   useSendinblueSubscription,
// } from '@terra-dev/sendinblue';

console.log('window.innerHeight', window.innerHeight, 'headerHeight', headerHeight);
const domHeight =
  window.innerWidth > landingMobileLayout
    ? (window.innerHeight > 796 ? (window.innerHeight - headerHeight) : 732) + 'px'
    : (window.innerHeight - headerHeight) + 'px';

export interface BetterSavingsProps {
  className?: string;
  disable3D?: boolean;
}

// should works now!!
function SubscribeForm() {
  // const [email, setEmail, validEmail] = useEmailInput();

  // const [subscribeEmail, { status }] =
  //   useSendinblueSubscription(sendinblueApiKey);

  return (
    <div className="subscribe">
      {/* <p>
        OpenSky Finance is a <span>time-based</span> NFT lending protocol built on top of aave.com which integrates peer-to-pool and peer-to-peer loans.
      </p> */}

      <div className="btn">
        <a href={links.app.url + 'earn'} target="web-app">
          {/* Earn */}
          <img src={earnBTC} className='btn-img' />
        </a>

        <a href={links.app.url + 'earn'} target="web-app">
          {/* Borrow */}
          <img src={earnETH} className='btn-img' />
        </a>
      </div>

      <a href="https://x.com/AaveGrants/status/1501994397918978052?s=20" target="blank" className="grant-link">
        Powered by <img src={aaveLogo} className="aave-logo" />
      </a>

      {/* {status === 'in-progress' ? (
        <div className="email">
          <MailOutline /> Please wait...
        </div>
      ) : status === 'success' ? (
        <div className="email">
          <MailOutline /> Thanks for subscribing.
        </div>
      ) : (
        <div className="email">
          <MailOutline />
          <InputBase
            type="email"
            fullWidth
            placeholder="Enter Your email address"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
          <button disabled={!validEmail} onClick={() => subscribeEmail(email)}>
            Subscribe
          </button>
        </div>
      )} */}
    </div>
  );
}

function measureGraphicsSize(windowInnerWidth: number): number {
  // return Math.max(200, Math.min(Math.floor(windowInnerWidth * 0.5), 800));
  // 594  327
  // landingMobileLayout
  return windowInnerWidth > landingMobileLayout ? 550 : 292;
}

// function measureHeight(windowInnerHeight: number): number {
//   return windowInnerHeight - headerHeight;
// }

function BetterSavingsBase({
  className,
  disable3D = false,
}: BetterSavingsProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const intersection = useElementIntersection({ elementRef: canvasRef });

  const isIntersecting = intersection?.isIntersecting ?? false;

  const [graphicsSize, setGraphicsSize] = useState<number>(() =>
    measureGraphicsSize(window.innerWidth),
  );

  // const [height] = useState<number>(() => measureHeight(window.innerHeight));

  useEffect(() => {
    function resize() {
      setGraphicsSize(measureGraphicsSize(window.innerWidth));
    }

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  useEffect(() => {
    if (disable3D || !isIntersecting) return;

    let gui: GUI | null = null,
      stats: Stats | null = null;

    if (process.env.NODE_ENV === 'development' && !isTouchDevice()) {
      gui = new GUI({ width: 300 });

      stats = new Stats();
      stats.showPanel(0);

      document.body.appendChild(stats.dom);
    }

    const regl = Regl({
      canvas: canvasRef.current!,
      attributes: {
        antialias: true,
        alpha: true,
      },
    });

    play(regl, animate(regl, gui, stats));

    return () => {
      stop();

      if (!!gui) {
        gui.destroy();
      }

      if (!!stats) {
        document.body.removeChild(stats.dom);
      }
    };
  }, [disable3D, isIntersecting]);

  return (
    <section
      className={className} >
      <div className="content-wrap"> 
        <div className="canvas-wrap">
          <canvas
            ref={canvasRef}
            width={graphicsSize}
            height={graphicsSize}
            style={{
              width: graphicsSize,
              height: graphicsSize,
              opacity: isIntersecting ? 1 : 0,
              border: disable3D ? '2px dashed blue' : undefined,
            }}
          />
        </div>
        <div className="contents">
          <div className="title">
            Put your NFTs to work
          </div>
          <SubscribeForm />
        </div>
      </div>
    </section>
  );
}

export const BetterSavings = styled(BetterSavingsBase)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 101%;

  // background: #14182C;
  height: ${domHeight};
  background-image: url(${bg});
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content-wrap {
    width: 100%;
    height: ${domHeight};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-size: 112px;
    font-weight: 700;
    color: #D6DAFF;
    margin-bottom: 2rem;
    font-family: 'Kanit';
  }

  .canvas-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contents {
    width: 100%;
    position: absolute;
    bottom: 16.7vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subscribe {
    > p {
      font-size: 2.17rem;
      color: #ffffff;
      width: 85%;
      span {
        color: #065dff;
      }
      line-height: 2.62rem;
    }

    .btn {
      margin-top: 4.5rem;
      width: 424px;
      display: flex;
      justify-content: space-between;
      
      .btn-img {
        width: 200px;
        height: 74px;
      }
    }

    .grant-link {
      font-family: 'Kanit';
      text-decoration: none;
      margin-top: 40px;
      color: #D6DAFF;
      font-size: 16px;
      font-weight: 900;
      display: flex;
      justify-content: center;
      align-items: center;
      .aave-logo {
        margin-left: 3px;
        margin-top: 0px;
        width: 78px;
        height: 24px;
      }
    }

    img {
      margin-top: 2.67rem;
    }

    .email {
      font-size: 17px;
      color: ${({ theme }) => theme.textColor};

      margin-top: 24px;

      display: flex;
      height: 56px;

      padding: 0 5px 0 30px;
      align-items: center;

      border-radius: 4px;

      width: 100%;
      max-width: 520px;

      background-color: #f6f6f6;

      svg {
        color: rgba(0, 0, 0, 0.65);
        font-size: 20px;
        margin-right: 15px;
        transform: translateY(1px);
      }

      input::placeholder {
        color: rgba(0, 0, 0, 0.25);
        opacity: 1;
      }

      button {
        cursor: pointer;

        width: 130px;

        border: 0;
        outline: none;
        background-color: transparent;

        padding: 4px 28px;
        border-radius: 0;

        border-left: 1px solid #e2e2e2;
        color: ${({ theme }) => theme.textColor};

        &:disabled {
          cursor: default;
          color: #b5b5b5;
        }
      }
    }
  }

  > canvas {
    flex-grow: 1;
    flex-shrink: 0;
    transition: opacity 2s ease-out;
  }

  @media (max-width: ${landingMobileLayout}px) {
    background-image: url(${mobileBg});
    background-size: 100% 100%;
    height: ${domHeight};
    .content-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
      > canvas {
        margin-top: 2rem;
      }
    }

    .title {
      font-size: 56px;
      font-weight: 600;
      line-height: 56px;
      color: #D6DAFF;
      margin-bottom: 2rem;
      font-family: 'Kanit';
      text-align: center;
    }

    .contents {
      width: 100%;
      position: initial;
      padding: 20px;
    }

    h2 {
      font-size: 40px;
      font-weight: 560;
      line-height: 48px;
      margin-top: 32px;
    }

    .subscribe {
      padding-bottom: 40px;
      > p {
        font-size: 20px;
        width: 100%;
      }
    }

    .subscribe {
      width: 100%;
      padding-bottom: 0;
      img {
        margin-top: 0;
      }
      .btn {
        display: flex;
        justify-content: space-evenly;
        margin-top: 0;
        width: 100%;
        
        .btn-img {
          width: auto;
          height: 48px;
        }
      }
    }
  }
`;
