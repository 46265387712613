// import { CircleArrowRight } from '@anchor-protocol/icons';
import { useElementIntersection } from '@terra-dev/use-element-intersection';
import { landingMobileLayout } from 'env';
import { useRef } from 'react';
import styled from 'styled-components';
import topBg from './assets/topbg.png';

import NFTBank from './assets/nft-bank.png';
import Rarible from './assets/Rarible.png';
import Aave from './assets/Aave.png';
import Chainlink from './assets/ChainLink.svg';
import AAVEGrantsDAO from './assets/aave-grants-dao.png';
import TheGraph from './assets/TheGraph.png';
import RaribleProtocol from './assets/rarible-protocol.png';
import Alchemy from './assets/alchemy.png';
import MetaCartel from './assets/MetaCartel.png';
import AlphaNonce from './assets/AlphaNonce.png';
import SNZ from './assets/SNZ.png';
import FBG from './assets/FBG.png';
import CollinStar from './assets/CollinStar.png';
import bg from './assets/bg5.webp';
import itemBg1 from './assets/item-bg1.png';
import itemBg2 from './assets/item-bg2.png';

export interface FrictionlessAcessProps {
  className?: string;
}

function FrictionlessAcessBase({ className }: FrictionlessAcessProps) {
  const elementRef = useRef<HTMLElement>(null);

  const elementIntersection = useElementIntersection({
    elementRef,
    threshold: 0.4,
    observeOnce: true,
  });

  return (
    <section
      ref={elementRef}
      className={className}
      data-intersection={elementIntersection?.isIntersecting}
      style={
        {
          // height: window.innerWidth > landingMobileLayout ? '880px' : 'auto',
        }
      }
    >
      <div className="content-wrap">
        <article>
          <h2> Partners </h2>
        </article>

        <figure>
          <div className="legos">
            <h3> Money Legos </h3>
            <div className="links">
              <div className="linkitem">
                <img src={Aave} alt="AAVE" />
                <span>Aave</span>
              </div>
            
              <div className="linkitem">
                <img src={Rarible} alt="Rarible" />
                <span>Rarible</span>
              </div>

              <div className="linkitem">
                <img src={Chainlink} alt="Chainlink" />
                <span>Chainlink</span>
              </div>
            
              <div className="linkitem">
                <img src={TheGraph} alt="The Graph" />
                <span>The Graph</span>
              </div>
            
              <div className="linkitem">
                <img src={NFTBank} alt="NFTBank" />
                <span>NFTBank</span>
              </div>
            </div>
          </div>
          <div className="grants">
            <h3> Integration Grants </h3>
            <div className='links'>
              <div className="linkitem">
                <img src={AAVEGrantsDAO} alt="AAVE Grants DAO" />
                <span>AAVE GRANTS DAO</span>
              </div>

              <div className="linkitem">
                <img src={RaribleProtocol} alt="Rarible Protocol" />
                <span>Rarible Protocol</span>
              </div>

              <div className="linkitem">
                <img src={Alchemy} alt="Alchemy" />
                <span>Alchemy</span>
              </div>
            </div>
          </div>
          <div className='capitalists'>
            <h3> Global Venture Capitalists </h3>
            <div className='links'>
              <a
                href="https://metacartel.xyz/"
                target="_blank"
                rel="noreferrer"
                className="venture small"
              >
                <img src={MetaCartel} alt="MetaCartel Ventures" />
              </a>
              <a
                href="https://www.alphanonce.com/"
                target="_blank"
                rel="noreferrer"
                className="venture big"
              >
                <img src={AlphaNonce} alt="Alpha Nonce" />
              </a>
              <a
                href="https://twitter.com/FBGCapital"
                target="_blank"
                rel="noreferrer"
                className="venture small"
              >
                <img src={SNZ} alt="SNZ" />
              </a>
              <a
                href="https://snzholding.com/"
                target="_blank"
                rel="noreferrer"
                className="venture big"
              >
                <img src={FBG} alt="FBG Capital" />
              </a>
              <a
                href="https://twitter.com/CollinStarCap"
                target="_blank"
                rel="noreferrer"
                className="venture big"
              >
                <img src={CollinStar} alt="Collin Star" />
              </a>
            </div>
          </div>
        </figure>
      </div>
    </section>
  );
}

export const FrictionlessAcess = styled(FrictionlessAcessBase)`
  // ---------------------------------------------
  // style
  // ---------------------------------------------
  display: flex;
  justify-content: center;

  // background: linear-gradient(180deg, #273865 0%, #071026 100%);
  width: 100vw;
  // background-size: 100% 100%;
  // background-image: url('${bg}');

  .content-wrap {
    width: 100rem;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  article {
    width: 100%;
    text-align: center;
    h2 {
      text-align: center;
      font-size: 88px;
      font-family: 'Kanit';
      color: #fff;
      margin-bottom: 7rem;
    }
  }

  .bottom-title {
    margin-top: 80px;
  }

  figure {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    h3 {
      margin-top: 1.5rem;
      font-size: 40px;
      font-family: 'Kanit';
      color: rgba(255,255,255,0.85);
    }
    .legos {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .grants {
      margin-top: 10rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .capitalists {
      margin-top: 10rem;
      max-width: 63.33rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
 
    .links {
      margin-top: 3.33rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      .linkitem {
        margin-top: 2.5rem;
        margin-right: 2.5rem;
        width: 17rem;
        height: 21rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        // background-color: #252D41;
        border-radius: 2rem;
      
        img {
          margin-top: 1rem;
          width: 10.3rem;
          height: 10.3rem;
          border-radius: 1rem;
        }
        span {
          color: #fff;
          margin-top: 2rem;
      
          font-size: 1.5rem;
          font-weight: 500;
          text-align: center;
      
          &:not(:last-child) {
            margin-right: 3rem;
          }
        }
      }
      .venture {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.315) 0%, rgba(255, 255, 255, 0.227344) 49.48%, rgba(255, 255, 255, 0.315) 100%), linear-gradient(0deg, rgba(3, 12, 36, 0.12), rgba(3, 12, 36, 0.12)), #FFFFFF;
        border: 1px solid #FFFFFF;
        box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.32), 1px 1px 2px rgba(0, 0, 0, 0.16);
        border-radius: 16px;
        margin-top: 3.33rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .small {
        width: 13.33rem;
        height: 13.33rem;
        img {
          width: 10rem;
          height: 10rem;
          object-fit: contain;
        }
      }
      .big {
        width: 30rem;
        height: 13.33rem;
        img {
          width: 26rem;
          height: 10rem;
          object-fit: contain;
        }
      }
    }
  }

  @media (max-width: ${landingMobileLayout}px) {
    margin: 0px;
    .content-wrap {
      max-width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 64px;
    }

    article {
      text-align: center;
      h2 {
        font-size: 36px;
        color: #fff;
        margin-left: 0;
        margin-bottom: 0;
        font-weight: 600;
      }
    }

    figure {
      width: 100vw;
      .legos {
        padding: 1rem;
        width: 100%;
        h3 {
          font-size: 24px;
        }
      }
      .grants {
        padding: 1rem;
        width: 100%;
        h3 {
          font-size: 24px;
        }
        .links {
          width: 100%;
          .linkitem {
            margin-top: 6px;
            width: 88px;
            height: 128px;
            background-color: transparent;
            background-image: url('${itemBg2}');
            background-repeat: no-repeat;
            background-size: 90px 90px;
            display: flex;
            justify-content: center;
            margin: 0;
            padding: 0;
            img {
              margin-top: 6px;
              width: 76px;
              height: 76px;
            }
            span {
              height: 28px;
            }
          }
        }
      }
      .capitalists {
        padding: 1rem;
        width: 100%;
        h3 {
          font-size: 24px;
        }
        .links {
          margin-top: 0;
        }
      }
      .links {
        justify-content: space-evenly;
        .linkitem {
          width: 104px;
          height: 128px;
          margin: 1rem;
          background-image: url('${itemBg1}');
          background-size: 104px 128px;
          justify-content: center;
          img {
            width: 64px;
            height: 64px;
          }
          span {
            font-size: 12px;
          }
        }
        .small {
          width: 12rem;
          height: 12rem;
          img {
            width: 7.5rem;
            height: 7.5rem;
            object-fit: contain;
          }
        }
        .big {
          width: 24rem;
          height: 12rem;
          img {
            width: 19.2rem;
            height: 7.5rem;
            object-fit: contain;
          }
        }
      }
    }
  }
`;
