// import { CircleArrowRight } from '@anchor-protocol/icons';
import { useElementIntersection } from '@terra-dev/use-element-intersection';
import { links, landingMobileLayout } from 'env';
// import { useEffect, useRef, useState } from 'react';
import { useRef } from 'react';

import styled from 'styled-components';
import img from './assets/omni-chain-solution.png';
import gifBg from './assets/gifBg.png';
import arrow from './assets/arrow-right.svg';
import bg from './assets/bg2.webp';

const domHeight =
  window.innerWidth > landingMobileLayout
    ? (window.innerHeight > 796 ? window.innerHeight : 796) + 'px'
    : 'auto';

export interface OmniChainSolutionProps {
  className?: string;
}

function OmniChainSolutionBase({ className }: OmniChainSolutionProps) {
  const elementRef = useRef<HTMLElement>(null);

  const elementIntersection = useElementIntersection({
    elementRef,
    threshold: 0.4,
    observeOnce: true,
  });

  // const [imageFlag, setimageFlag] = useState(true);
  // const timerID = useRef(0);

  // useEffect(() => {
  //   timerID.current = window.setInterval(() => {
  //     setimageFlag((imageFlag) => !imageFlag);
  //   }, 5000);
  //   return () => clearInterval(timerID.current);
  // }, []);

  return (
    <section
      ref={elementRef}
      className={className}
      data-intersection={elementIntersection?.isIntersecting}
      style={
        {
          // height: window.innerWidth > landingMobileLayout ? '880px' : 'auto',
        }
      }
    >
      <div className="content-wrap">
        <figure>
          <img src={img} />
        </figure>

        <article>
          <h2> Lender and Borrower Benefits </h2>
          <p>
            Lenders deposit their BTC or ETH to earn passive income, and borrowers pledge their BTC or ETH NFTs to borrow.
          </p>

          <a
            className="btn-link"
            href={links.peerToPool.url}
            target="_blank"
            rel="noreferrer"
          >
            <span className='learn-more'>{links.peerToPool.text}</span>
            <span className='arrow'></span>
          </a>
        </article>
      </div>
    </section>
  );
}

export const OmniChainSolution = styled(OmniChainSolutionBase)`
// ---------------------------------------------
  // style
  // ---------------------------------------------
  width: 100vw;
  height: ${domHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #14182C;

  .content-wrap {
    width: 100%;
    display: flex;
    margin-top: 8rem;
    margin-bottom: 8rem;
    justify-content: space-evenly;
    align-items: center;

    .title {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      img {
        width 4rem;
        height: 4rem;
        margin-right: 1rem;
      }
      span {
        font-weight: 600;
        font-size: 2.5rem;
      }
    }
  }

  article {
    width: 500px;
    flex-grow: 0;
    flex-shrink: 0;
    
    h2 {
      font-family: 'Kanit';
      font-size: 58px;
      font-weight: 600;
      line-height: 64px;
      color: #ffffff;
      margin-bottom: 16px;
    }

    p {
      margin-top: 8px;
      font-size: 20px;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.65);
      word-break: break-word;
      white-space: break-spaces;
    }

    a.btn-link {
      margin-top: 48px;

      display: inline-flex;
      justify-content: center;
      align-items: center;

      border: 0px solid #065dff;
      border-radius: 2rem;

      padding: 0;
      color: #065dff;
      font-weight: 400;
      font-size: 1.67rem;

      .learn-more {
        line-height: 24px;
        font-size: 20px;
        background-image: linear-gradient(to right, #065DFF, #FB43FF);
        -webkit-background-clip: text;
        color: transparent;
      }

      .arrow {
        margin-left: 4px;
        width: 12px;
        height: 12px;
        background-image: url('${arrow}');
        background-repeat: no-repeat;
        background-size: 12px 12px;
      }
    }
  }

  figure {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 52rem;
      height: auto;
    }
  }

  // ---------------------------------------------
  // animation
  // ---------------------------------------------
  // article {
  //   h2,
  //   p {
  //     opacity: 0;
  //     transform: scale(0.8);
  //     transition: opacity 1s ease-out, transform 0.1s ease-in-out;
  //   }
  // }

  figure {
    opacity: 0;
    transform: translateX(-10%) scale(1.1);
    transition: opacity 1s ease-out, transform 0.3s ease-in-out;
  }

  &[data-intersection='true'] {
    article {
      h2,
      p {
        opacity: 1;
        transform: none;
      }
    }

    figure {
      opacity: 1;
      transform: none;
    }
  }

  @media (max-width: ${landingMobileLayout}px) {
    // background: #f4f5f7;
    margin-bottom: 0px;
    .content-wrap {
      margin-left: 0px;
      flex-direction: column;
      justify-content: center;
      padding: 0px 20px;
    }

    article {
      width: 100%;
      padding-left: 6px;
      padding-right: 6px;
      padding-top: 32px;

      h2 {
        font-size: 36px;
        line-height: 36px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
      }
      a.btn-link {
        margin-top: 24px;
        .learn-more {
          font-size: 16px;
        }
      }
    }

    figure {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
`;
