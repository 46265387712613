import { AppProviders } from 'base/AppProviders';
import { Header } from 'components/Header';
import { Index } from 'pages/index';
import { Market } from 'pages/market-simple';
import { Redirect, Route, Switch } from 'react-router-dom';
// import topBg from './assets/top-bg.png';
// import { landingMobileLayout } from 'env';

import LogRocket from 'logrocket';
LogRocket.init('32txzn/lading-home');
LogRocket.identify(new Date().toString(), {})

export function App() {
  // const width = window.innerWidth;
  // const height =
  //   width > landingMobileLayout ? window.innerHeight + 'px' : 'auto';
  return (
    <AppProviders enableWatchConnection={false}>
      <div style={{
        background: '#14182C'
      }}>
        <Header />
        <div style={{
          background: 'linear-gradient(to right, #01D1FF 0%, #7B3BE3 50%, #065DFF 100%)',
          transform: 'matrix(1, 0, 0, -1, 0, 0)',
          height: '4px'
        }}></div>
        <Switch>
          {/*<Route exact path="/" component={Index} />*/}
          {/*<Route path="/market" component={Market} />*/}
          {/*<Redirect exact path="/stablecoins" to="/market" />*/}
          {/*<Route path="/stablecoins/:stableCoinId" component={StableCoins} />*/}
          {/*<Redirect exact path="/bassets" to="/market" />*/}
          {/*<Route path="/bassets/:bAssetId" component={BAssets} />*/}
          {/*<Route path="/contact" component={Contact} />*/}
          {/*<Redirect to="/" />*/}

          <Route exact path="/" component={Index} />
          {/* <Route path="/dashboard" component={Market} /> */}
          <Redirect to="/" />
        </Switch>
      </div>
    </AppProviders>
  );
}
