import { links } from 'env';
import styled from 'styled-components';
// import { Labtop } from '@anchor-protocol/icons';

export interface WebAppButtonProps {
  className?: string;
}

const hasReachLaunchTime = () => {
  const launchTime = Date.UTC(2021, 6, 15, 8, 0, 0);
  const timeLeft = launchTime - Date.now();
  return timeLeft <= 0;
};

function WebAppButtonBase({ className }: WebAppButtonProps) {
  let theLink = links.app.comingSoonUrl;
  if (hasReachLaunchTime()) {
    theLink = links.app.url;
  }
  return (
    <a className={`webapp ${className}`} href={theLink} target="_blank">
      {links.app.text}
    </a>
  );
}

export const WebAppButton = styled(WebAppButtonBase)`
  display: inline-flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;

  border-radius: 3rem;
  outline: none;
  cursor: pointer;

  background: linear-gradient(to right, #065DFF, #FB43FF);
  width: 160px;
  height: 32px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
`;
