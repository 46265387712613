import { Menu, MenuClose } from '@anchor-protocol/icons';
import { IconToggleButton } from '@terra-dev/neumorphism-ui/components/IconToggleButton';
import { WebAppButton } from 'components/Header/WebAppButton';
import { headerHeight, links, meta } from 'env';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import topLogo from './top-logo.png';

export interface MobileHeaderProps {
  className?: string;
  color: 'dark' | 'light';
}

function MobileHeaderBase({ className, color }: MobileHeaderProps) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <header
        className={className}
        data-dark={color === 'dark'}
        data-open={open}
      >
        {open && (
          <nav>
            {/* {links.dashboard.show && (
              <a href={links.dashboard.url} target="_blank" rel="noreferrer">
                {links.dashboard.text}
              </a>
            )} */}

            <a href={links.twitter} target="_blank" rel="noreferrer">
              TWITTER
            </a>

            <a href={links.telegram} target="_blank" rel="noreferrer">
              TELEGRAM
            </a>

            {/* <a href={links.whitepaper.url} target="whitepaper">
              {links.whitepaper.text}
            </a> */}

            <a href={links.docs.url} target="FAQ">
              {links.docs.text}
            </a>
            <WebAppButton />
          </nav>
        )}
        <section>
          <Link className="top-logo" to="/" onClick={() => setOpen(false)}>
            {meta.headerTitle}
          </Link>
          <IconToggleButton
            on={open}
            onChange={setOpen}
            onIcon={MenuClose}
            offIcon={Menu}
          />
        </section>
      </header>
      {open && <div style={{ height: headerHeight }} />}
    </>
  );
}

const slide = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0.7;
  }
  
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const MobileHeader = styled(MobileHeaderBase)`
  padding: 0 15px;
  // ---------------------------------------------
  // style
  // ---------------------------------------------
  width: 100vw;
  > section {
    background-color: none;

    a {
      text-decoration: none;
      color: #333333;
    }

    button {
      color: #333333;
    }
  }

  > nav {
    padding-top: 64px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    a:not(.webapp) {
      font-size: 24px;
      font-weight: 400;
      text-decoration: none;

      color: #696969;

      &:hover {
        color: #515151;
      }

      &.active {
        color: #333333;
      }
    }
  }

  &[data-dark='true'] {
    > section {
      background-color: none;

      a {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    nav {
      background-color: #fff;
      font-size: 24px;
      font-weight: 400;

      a:not(.webapp) {
        color: rgba(3, 12, 36, 0.65);
      }

      .webapp {
        font-size: 20px;
        width: 180px;
        height: 48px;
      }
    }
  }

  // ---------------------------------------------
  // layout
  // ---------------------------------------------
  > section {
    position: relative;
    height: 64px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      font-size: 16px;
      font-weight: 900;
    }

    a.top-logo {
      display: inline-block;
      width: 138px;
      height: 28px;
      text-indent: -9999px;
      background-image: url('${topLogo}');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    svg {
      font-size: 32px;
    }
  }

  > nav {
    position: absolute;
    top: ${headerHeight}px;
    left: 0;
    width: 100vw;
    height: calc(100vh - ${headerHeight}px);

    display: flex;
    flex-direction: column;

    a {
      margin-bottom: 48px;
    }

    button {
      margin-top: 0px;
    }

    animation: ${slide} 0.3s cubic-bezier(0.655, 1.075, 0.8, 0.995);
  }

  &[data-open='true'] {
    position: fixed;
    z-index: 10000;
    top: 0;
    width: 100vw;
  }
`;
