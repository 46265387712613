import { auditMeasure } from '@terra-dev/audit-fastdom';
import { isTouchDevice } from '@terra-dev/is-touch-device';
import { landingMobileLayout } from 'env';
import { FrictionlessAcess } from 'pages/index/components/FrictionlessAcess';
import { useEffect, useMemo, useRef } from 'react';
import SmoothScroll from 'smooth-scroll';
import styled from 'styled-components';
import { BetterSavings } from './components/BetterSavings';
// import { BetterYield } from './components/BetterYield';
import { EasierIntegrations } from './components/EasierIntegrations';
import { OmniChainSolution } from './components/OmniChainSolution';

import { Subscribe } from 'pages/index/components/Subscribe';
import { Liquidation } from './components/Liquidation';
import { Auditors } from './components/Auditors';
import { CapitalEfficiency } from './components/CapitalEfficiency';
import { FlexibleHybridMechanism } from './components/FlexibleHybridMechanism';
import bg from './components/assets/bg.png';

export interface IndexProps {
  className?: string;
}

const ANIMATION_OFFSET = 1000;

function IndexBase({ className }: IndexProps) {
  const scrollTarget = useRef<HTMLDivElement>(null);
  const scroll = useMemo(() => new SmoothScroll(), []);

  useEffect(() => {
    let inScroll = false;

    function endPaging(event: WheelEvent) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      if (event.deltaY >= 1 && !inScroll) {
        inScroll = true;
        if (process.env.NODE_ENV === 'development') {
          console.log('SCROLL TO NEXT PAGE!');
        }

        scroll.animateScroll(scrollTarget.current, undefined, {
          easing: 'easeInOutCubic',
        });

        setTimeout(() => {
          if (process.env.NODE_ENV === 'development') {
            console.log('END PAGING!');
          }
          window.removeEventListener('wheel', endPaging);
          inScroll = false;
        }, ANIMATION_OFFSET);
      }
    }

    const startPaging = auditMeasure(() => {
      const scrollY = window.scrollY;

      if (scrollY === 0) {
        if (process.env.NODE_ENV === 'development') {
          console.log('START PAGING!');
        }
        window.addEventListener('wheel', endPaging, { passive: false });
      }
    });

    if (!isTouchDevice()) {
      window.addEventListener('scroll', startPaging);
      startPaging();
    }

    return () => {
      window.removeEventListener('scroll', startPaging);
      window.removeEventListener('wheel', endPaging);
    };
  }, [scroll]);

  return (
    <div className={className}>
      <BetterSavings disable3D={process.env.NODE_ENV === 'development'} />
      <ResponsiveContainer ref={scrollTarget}>
        {/* <BetterYield /> */}
        {/* <DynamicCurve /> */}
        {/* <EasierIntegrations /> */}
        <OmniChainSolution />
        <FlexibleHybridMechanism />
        <CapitalEfficiency />
        <Liquidation />
        <div className='container-bg'>
          <FrictionlessAcess />
          <Auditors />
          <Subscribe />
        </div>
      </ResponsiveContainer>
    </div>
  );
}

export const ResponsiveContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${landingMobileLayout}px) {
    max-width: 100vw;
    padding: 0;

    > :not(:last-child) {
      // margin-bottom: 0;
    }
  }
`;

export const Index = styled(IndexBase)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #14182C;

  .container-bg {
    background-size: 100% 100%;
    background-image: url('${bg}');
  }

  a.btn-link {
    display: inline-block;
    text-decoration: none;
    border: 1px solid #065dff;
    outline: none;
    padding: 10px 30px;
    font-size: 14px;
    color: #065dff;
    font-weight: 700;
    border-radius: 80px;
  }

  @media (max-width: ${landingMobileLayout}px) {
    max-width: 100vw;
  }
`;
