// import { WebAppButton } from 'components/Header/WebAppButton';
import { links, meta } from 'env';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import topLogo from './top-logo.png';
import { WebAppButton } from './WebAppButton';
export interface DesktopHeaderProps {
  className?: string;
  color: 'dark' | 'light';
}

function DesktopHeaderBase({ className, color }: DesktopHeaderProps) {
  return (
    <header className={className} data-dark={color === 'dark'}>
      <div>
        <section>
          <Link className="top-logo" to="/">
            {meta.headerTitle}
          </Link>
        </section>
        <nav>
          {/* {links.dashboard.show && (
            <a href={links.dashboard.url} target="_blank" rel="noreferrer">
              {links.dashboard.text}
            </a>
          )} */}
          {/* <a href={links.whitepaper.url} target="_blank" rel="noreferrer">
            {links.whitepaper.text}
          </a>

          <a href={links.faq.url} target="_blank" rel="noreferrer">
            {links.faq.text}
          </a> */}

          {/* <div className="drop">
            <span>Govern</span>
            <div className="drop_con">
              <a href={links.whitepaper.url} target="_blank" rel="noreferrer">
                {links.whitepaper.text}
              </a>

              <a href={links.faq.url} target="_blank" rel="noreferrer">
                {links.faq.text}
              </a>
            </div>
          </div> */}

          <a href={links.twitter} target="_blank" rel="noreferrer">
            TWITTER
          </a>

          <a href={links.telegram} target="_blank" rel="noreferrer">
            TELEGRAM
          </a>

          <a href={links.docs.url} target="_blank" rel="noreferrer">
            {links.docs.text}
          </a>

          {/* <div className="drop">
            <span>Docs</span>
            <div className="drop_con">
              <a href={links.whitepaper.url} target="_blank" rel="noreferrer">
                {links.whitepaper.text}
              </a>

              <a href={links.faq.url} target="_blank" rel="noreferrer">
                {links.faq.text}
              </a>
            </div>
          </div> */}
        </nav>

        <section className="webapp">{links.app.show && <WebAppButton />}</section>
      </div>
    </header>
  );
}

export const DesktopHeader = styled(DesktopHeaderBase)`
  background: #14182C;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 100%;
    max-width: 160rem;
    display: flex;
    align-items: center;
    height: 7.11rem;

    nav {
      width: 25rem;
      a:not(:last-child) {
        margin-right: 28px;
      }

      margin-right: 5.42rem;
    }
    z-index: 999;
    a {
      text-decoration: none;
    }

    // logo
    > :first-child {
      flex: 1;
      font-size: 16px;
      font-weight: 600;

      a {
        color: #333333;
      }
    }

    a.top-logo {
      display: inline-block;
      width: 15.3rem;
      height: 3.11rem;
      margin-left: 4.44rem;
      text-indent: -9999px;
      background-image: url('${topLogo}');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    nav {
      a {
        font-size: 1.5rem;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.85);

        &:hover {
          color: #515151;
        }

        &.active {
          font-weight: 900;
          color: #333333;
        }
      }
    }
    
    .webapp {
      margin-right: 4.44rem;
    }

    &[data-dark='true'] {
      background-color: rgba(0, 0, 0, 0);
      display: flex;
      justify-content: center;
      > :first-child {
        a {
          color: rgba(255, 255, 255, 0.5);
        }
      }

      nav {
        display: flex;
        align-items: center;
        height: 50px;
        a {
          color: rgba(3, 12, 36, 0.65);

          &:hover {
            color: rgba(3, 12, 36, 0.95);
          }

          &.active {
            color: rgba(3, 12, 36, 1);
          }
        }
      }
    }
  }

  .drop {
    cursor: pointer;
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: rgba(3, 12, 36, 0.65);
    span {
      line-height: 50px;
    }
  }
  .drop_con {
    display: none;
    position: absolute;
    top: 45px;
    background-color: #000000;
    min-width: 90px;
    padding: 12px 16px;

    a {
      display: block;
      margin-top: 10px;
      color: #000;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
  .drop:hover .drop_con {
    display: block;
  }
`;
